<template>
    <FormKit
        type="form"
        :actions="false"
        form-class="w-full"
        :incomplete-message="false"
        @submit="modifyCustomerAddressGroup"
    >
        <div class="flex w-full flex-col content-center justify-items-center gap-4">
            <h2>
                {{ hasPostalCode() ? $t('shop.cart.limitations.ChangePostalCode') : $t('shop.cart.limitations.AddPostalCode') }}
            </h2>
            <div class="flex w-full flex-col gap-5 md:flex-row">
                <div class="flex w-full flex-row gap-4 md:w-6/12">
                    <FormKit
                        id="postalCode"
                        v-model="newAddressGroup.postalCode"
                        type="text"
                        name="postalCode"
                        :validation="[['required'], ['matches', /^\d{4}\s?[A-Za-z]{2}$/]]"
                        :placeholder="t('postalCode')"
                        :validation-messages="{
                            required: `${t('error.postalCodeRequired')}`,
                            matches: `${t('error.postalCodeInvalid')}`
                        }"
                        :classes="{
                            outer: `${webshopSettings().global?.shouldShowStreetNumberForDelivery ? 'w-8/12' : 'w-full'}`
                        }" />
                    <FormKit
                        v-if="webshopSettings().global?.shouldShowStreetNumberForDelivery"
                        id="streetNumber"
                        v-model="newAddressGroup.streetNumber"
                        type="text"
                        name="streetNumber"
                        validation="required"
                        :validation-messages="{
                            required: `${t('error.streetNumberRequired')}`
                        }"
                        :placeholder="t('streetNumber')"
                        :classes="{
                            outer: 'w-4/12'
                        }" />
                </div>
                <FormKit type="submit">
                    {{ hasPostalCode() ? $t('shop.cart.limitations.ChangePostalCode') : $t('shop.cart.limitations.AddPostalCode') }}
                </FormKit>
            </div>
        </div>
    </FormKit>
</template>

<script lang="ts" setup>
    import { checkout } from '~/store/checkout'
    import { webshopSettings } from '~/store/webshopSettings'
    import { hasPostalCode } from '~/composables/orderLimitations'
    interface ModifyPostalCode {
        closeModal: Function
    }
    const props = withDefaults(defineProps<ModifyPostalCode>(), {
        closeModal: () => {}
    })
    const { t } = useI18n()
    const newAddressGroup = ref({
        postalCode: checkout().input.customer.addressGroup.postalCode,
        streetNumber: checkout().input.customer.addressGroup.streetNumber
    })
    function modifyCustomerAddressGroup () {
        checkout().setCustomerAdressGroup(newAddressGroup.value.postalCode!, newAddressGroup.value.streetNumber!)
        props.closeModal()
    }
</script>
