<template>
    <Tooltip
        :container="false"
        :triggers="['hover']"
        :aria-id="ariaId"
        :hide-triggers="['hover']"
    >
        <div
            :class="defaultSlotClasses"
            @click.stop>
            <slot />
        </div>
        <template #popper>
            <slot name="popper" />
        </template>
    </Tooltip>
</template>

<script setup lang="ts">
    import { Tooltip } from 'floating-vue'
    defineProps<TooltipProps>()
    const ariaId = useId()
    interface TooltipProps {
        defaultSlotClasses?: string
    }
</script>
