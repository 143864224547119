import capitalize from 'lodash.capitalize'
import { webshopSettings } from '~/store/webshopSettings'
import { useTranslation } from '~/composables/useTranslation'
import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
import type { Message } from '~/types/local-types'

export function isCompletelyClosed (): boolean {
    return !!webshopSettings()?.global?.emergencyBrake?.closedTemporarily
}

export function isClosedForDispatchType (dispatchType: DispatchTypeOrderBuddy): boolean {
    if (dispatchType === DispatchTypeOrderBuddy.DELIVER && webshopSettings()?.global?.emergencyBrake?.deliveryClosedTemporarily) {
        return true
    } else if (dispatchType === DispatchTypeOrderBuddy.PICK_UP && webshopSettings()?.global?.emergencyBrake?.pickupClosedTemporarily) {
        return true
    }
    return false
}

export function getDelayForDispatchType (dispatchType: DispatchTypeOrderBuddy): number | boolean {
    if (dispatchType === DispatchTypeOrderBuddy.DELIVER && webshopSettings()?.global?.emergencyBrake?.deliveryMinTimeTemporarily) {
        return webshopSettings()?.global?.emergencyBrake?.deliveryMinTimeTemporarily ?? 0
    } else if (dispatchType === DispatchTypeOrderBuddy.PICK_UP && webshopSettings()?.global?.emergencyBrake?.pickupMinTimeTemporarily) {
        return webshopSettings()?.global?.emergencyBrake?.pickupMinTimeTemporarily ?? 0
    }
    return false
}

export function getEmergencyBreakNotification (dispatchType: DispatchTypeOrderBuddy): Message | Record<string, never> {
    const translationPrefix = dispatchType === DispatchTypeOrderBuddy.DELIVER ? 'delivery' : 'pickup'
    if (isCompletelyClosed()) {
        return {
            message: `${capitalize(useTranslation('shop.emergencyBreak.closedTemporarily'))}. ${capitalize(useTranslation('shop.emergencyBreak.apologies'))}.`,
            type: 'error'
        }
    } else if (isClosedForDispatchType(dispatchType)) {
        return {
            message: `${capitalize(useTranslation(`shop.emergencyBreak.${translationPrefix}ClosedTemporarily`))}. ${capitalize(useTranslation('shop.emergencyBreak.apologies'))}.`,
            type: 'error'
        }
    } else if (getDelayForDispatchType(dispatchType)) {
        // TODO: Add delay times to the emergency break messages
        return {
            message: `${capitalize(useTranslation(`shop.emergencyBreak.${translationPrefix}MinTimeTemporarily`))} ${useTranslation('shop.emergencyBreak.with')} ${getDelayForDispatchType(dispatchType)} ${useTranslation('shop.emergencyBreak.minutes')}. ${capitalize(useTranslation('shop.emergencyBreak.apologies'))}.`,
            type: 'info'
        }
    }
    return {}
}

export function getEmergencyBreakActive (dispatchType: DispatchTypeOrderBuddy) {
    return Object.keys(getEmergencyBreakNotification(dispatchType)).length
}
