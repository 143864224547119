<template>
    <div
        class="flex items-center gap-2 rounded-lg p-4 text-xs font-bold text-white"
        :class="{
            'bg-blue-600': type === 'info',
            'bg-green-600': type === 'success',
            'bg-yellow-600': type === 'warning',
            'bg-red-600': type === 'error'
        }">
        <Icon v-if="type && getNotificationIcon" :icon="getNotificationIcon" :auto-align="false" />
        <slot />
    </div>
</template>

<script setup lang="ts">
    import Icon from '~/components/Icon.vue'
    import IconInfo from '@/assets/icons/info.svg'
    import IconSuccess from '@/assets/icons/check.svg'
    import IconWarning from '@/assets/icons/warning.svg'
    import IconError from '@/assets/icons/error.svg'
    const props = defineProps({
        type: String
    })
    const getNotificationIcon = computed(() => {
        switch (props.type) {
        case 'info':
            return IconInfo
        case 'success':
            return IconSuccess
        case 'warning':
            return IconWarning
        case 'error':
            return IconError
        default:
            return false
        }
    })
</script>
