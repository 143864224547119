import { checkout } from '~/store/checkout'
import { isClosedForDispatchType, isCompletelyClosed } from '~/composables/emergencyBreak'
import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
import { webshopSettings } from '~/store/webshopSettings'
import { cart } from '~/store/cart'
export function isCheckoutDisabled () {
    // function that returns whether checkout is disabled
    const dispatchType = checkout().input.dispatchType
    if (isCompletelyClosed() || isClosedForDispatchType(dispatchType)) {
        // If the shop is completely closed, or closed for the dispatch type, checkout is disabled
        return true
    } else if (!cart().hasCartProducts) {
        return true
    } else if (dispatchType === DispatchTypeOrderBuddy.DELIVER && !meetsMinimumOrderThresholdForDelivery()) {
        // If the dispatch type is delivery and the order does not meet the minimum order threshold, checkout is disabled
        return true
    } else if (dispatchType === DispatchTypeOrderBuddy.PICK_UP && !meetsMinimumOrderThresholdForPickup()) {
        // If the dispatch type is pickup and the order does not meet the minimum order threshold, checkout is disabled
        return true
    }
    return false
}
export function isOrderDisabled () {
    // TODO: refine more
    // function that returns whether ordering is disabled
    const dispatchType = checkout().input.dispatchType
    if (dispatchType === DispatchTypeOrderBuddy.DELIVER && hasPostalCode() && !isPostalCodeInRange()) {
        // If there is a postal code entered, but it is not in range, ordering is disabled
        // TODO: this should only be when the dispatch type is delivery
        return true
    }
    return isCheckoutDisabled()
}
export function hasPostalCode (): boolean {
    return checkout()?.input?.customer?.addressGroup?.postalCode !== ''
}
export function isPostalCodeInRange (): boolean {
    return webshopSettings().getCurrentDeliveryArea !== undefined
}
export function getMinimumOrderThresholdGlobal (): number {
    // TODO: Make a ticket for OB BE to set a default of 0 instead of Maybe<Scalars['Float']['output']>
    return webshopSettings().global?.delivery?.minimumOrderThreshold ?? 0
}
export function getMinimumOrderThresholdForDeliveryCurrentArea (): number {
    // TODO: Make a ticket for OB FE to expose this function + to implement minimum order threshold for pickup of 0 instead of null
    const currentDeliveryArea = webshopSettings().getCurrentDeliveryArea
    return currentDeliveryArea ? currentDeliveryArea.minimumOrderThreshold ?? 0 : 0
}
export function getDeliveryMinimum (): number {
    return getMinimumOrderThresholdForDeliveryCurrentArea() === 0 ? getMinimumOrderThresholdGlobal() : getMinimumOrderThresholdForDeliveryCurrentArea()
}
export function meetsMinimumFreeDeliveryThreshold (): boolean {
    return cart().getCartSubPriceLocal >= webshopSettings()?.getCurrentDeliveryArea?.freeDeliveryThreshold
}
export function meetsMinimumOrderThresholdForDelivery (): boolean {
    const meetsMinimumOrderTresholdGlobal = cart().getCartSubPriceLocal >= getMinimumOrderThresholdGlobal()
    if (hasPostalCode() && isPostalCodeInRange()) {
        // If there is a postcode entered, and it is in range
        if (getMinimumOrderThresholdForDeliveryCurrentArea() === 0) {
            // If there is a no minimum order threshold for the current delivery area then we try to use the global minimum order threshold
            return meetsMinimumOrderTresholdGlobal
        } else {
            // If there is a minimum order threshold for the current delivery area
            return cart().getCartSubPriceLocal >= getMinimumOrderThresholdForDeliveryCurrentArea()
        }
    }
    return meetsMinimumOrderTresholdGlobal
}
export function meetsMinimumOrderThresholdForPickup (): boolean {
    // TODO: Write ticket to implement minimum order threshold for pickup, backend does not support it yet
    return true
}
