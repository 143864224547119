<template>
    <Notification :type="type">
        <span>
            {{ $t('shop.cart.limitations.noDeliveryOnPostCode') }}
            <template v-if="postCodeSwitchModal">
                {{ $t('shop.cart.limitations.pleaseChooseAnother') }}
                <button type="button" @click="openModal">
                    <span class="underline">{{ $t('postalCode').toLowerCase() }}</span>
                </button>.
                <template v-if="webshopSettings().pickupIsEnabled">
                    {{ ' ' }}{{ $t('shop.cart.limitations.noDeliveryChangePickupBeforeLink') }}
                    <button type="button" @click="checkout().setDispatchType(DispatchTypeOrderBuddy.PICK_UP, true)">
                        <span class="underline">{{ $t('shop.cart.limitations.noDeliveryChangePickupLink') }}</span>
                    </button>
                </template>
            </template>
            <button v-else-if="webshopSettings().pickupIsEnabled" type="button" class="underline first-letter:uppercase" @click="navigateToDispatchType(DispatchTypeOrderBuddy.PICK_UP)">
                {{ $t('shop.cart.limitations.noDeliveryChangePickupLink') }}
            </button>
        </span>
    </Notification>
</template>

<script lang="ts" setup>
    import { useModalSlot } from 'vue-final-modal'
    import { checkout } from '~/store/checkout'
    import { messagesModal } from '~/composables/messagesModal'
    import ModalContentModifyPostalCode from '~/components/Modal/Content/ModalContentModifyPostalCode.vue'
    import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
    import Notification from '~/components/Notification/Notification.vue'
    import { webshopSettings } from '~/store/webshopSettings'
    import { navigateToDispatchType } from '~/composables/navigateToDispatchType'
    defineProps({
        type: String,
        postCodeSwitchModal: {
            type: Boolean,
            default: true
        }
    })
    function openModal () {
        const modalInstance = messagesModal({
            default: useModalSlot({
                component: ModalContentModifyPostalCode,
                attrs: {
                    closeModal: () => {
                        modalInstance.close()
                    }
                }
            })
        })
    }
</script>
